@import './settings.scss';

@mixin icon-btn() {
  border-radius: 0.3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $gray-700;
  height: 2rem;
  box-shadow: 5px 5px 10px 0 rgba(0, 0, 0, 0.2),
    -5px -5px 10px 0 rgba(255, 255, 255, 0.5);

  &:active {
    box-shadow: inset 5px 5px 10px 0 rgba(0, 0, 0, 0.2),
      inset -5px -5px 10px 0 rgba(255, 255, 255, 0.5);
  }
}

.custome-table {
  border-radius: 0.5rem;
  border-left: $gray-300 solid 1px;
  border-top: $gray-300 solid 1px;
  border-right: $gray-300 solid 1px;
  line-height: 1.3rem;

  .table td {
    padding: 0.3rem;
    vertical-align: middle;
  }
}

.table-header {
  min-width: 4em;
  cursor: pointer;
  font-weight: 500;
}

.icon-delete {
  @include icon-btn();

  &:hover {
    color: $red;
  }

  &:active {
    color: $red-600;
  }
}

.icon-download {
  @include icon-btn();

  &:hover {
    color: $blue;
  }

  &:active {
    color: $blue-600;
  }
}

.icon-edit {
  @include icon-btn();

  &:hover {
    color: $orange;
  }

  &:active {
    color: $orange-600;
  }
}
