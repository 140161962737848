// Required
// 1. Include functions first (so you can manipulate colors, SVGs, calc, etc)
@import "bootstrap/scss/functions";
// 3. Include remainder of required Bootstrap stylesheets
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";

/* -------begin customization-------- */
/* change the primary theme color to blue */
$theme-colors: ("primary": $blue-600,
  "secondary": $secondary,
  "success": $cyan-300,
  "info": $indigo-400,
  "warning": $warning,
  "danger": $danger,
  "light": $light,
  "dark": $dark);
/* -------end customization-------- */
/* finally, import Bootstrap to set the changes! */
@import "bootstrap/scss/bootstrap";
