@import './settings.scss';

button.nav-link {
  @include media-breakpoint-down(sm) {
    padding: 8px;
  }
}


.was-validated .form-check-input.expiry-input:valid:checked,
.form-check-input.expiry-input.is-valid:checked {
  background-color: #316cf4;
}

.was-validated .form-check-input.expiry-input:valid,
.form-check-input.expiry-input.is-valid {
  border-color: #cfd4d9;
}

.doc-expired {
  background-color: $red;
}

.doc-expiring-soon {
  background-color: $yellow;
}
