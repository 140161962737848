@import "./settings.scss";

* {
  box-sizing: border-box;
}

// transition: all 0.1s ease;
body {
  // min-height: 100vh;
  // @include media-breakpoint-up(md) {
  //   height: inherit;
  // }
  margin: 0;
  min-width: 320px;
  position: relative;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ag-theme-material,
.ag-theme-material-dark {
  --ag-cell-horizontal-padding: 9px;
  --ag-input-focus-border-color: var(--ag-input-border-color);
  --ag-checkbox-checked-color: rgb(144, 202, 249); // TODO depends on main theme
  --ag-checkbox-unchecked-color: var(--ag-material-primary-color);
  --ag-grid-size: 7px;
  // --ag-list-item-height: 30px;

  // header-separator
  --ag-header-column-separator-display: block;
  --ag-header-column-separator-height: 50%;
  --ag-header-column-separator-width: var(--ag-row-border-width);
  --ag-header-column-separator-color: var(--ag-border-color);

  // header-resize-handle
  // --ag-header-column-resize-handle-display: block;
  // --ag-header-column-resize-handle-height: 30%;
  // --ag-header-column-resize-handle-width: calc(var(--ag-row-border-width) * 3);
  // --ag-header-column-resize-handle-color: var(--ag-border-color);

  &.ag-theme-material-light-blue {
    --ag-background-color: #e3f2fd;
    --ag-header-background-color: #e3f2fd;
    --ag-header-cell-hover-background-color: white;
    --ag-header-cell-moving-background-color: white;
    --ag-row-hover-color: white;
    --ag-border-color: #90a4ae;
  }

  .ag-row.clickable {
    &:hover {
      cursor: pointer;
    }
  }

  .ag-cell.no-padding {
    padding: 0;
    margin: 0;
  }

  .ag-cell-focus:focus-within {
    border: none !important;
  }
}
