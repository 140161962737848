@import './settings.scss';

form {

  .form-select:disabled,
  .form-control:disabled {
    color: inherit;
    opacity: 1;
  }
}

.pf-user-card {
  .card-top {
    height: 9rem;

    & img {
      width: 100%;
      height: 100%;
      object-position: center;
      object-fit: cover;
      overflow: hidden;
      border-top-left-radius: 0.25rem;
      border-top-right-radius: 0.25rem;
    }
  }

  & .card-body {
    margin-top: -6rem;
    text-align: center;
    text-transform: none;

    & .pf-user-img {
      width: 10rem;
      height: 10rem;
      margin: 0 auto;

      & img {
        width: 100%;
        height: 100%;
        overflow: hidden;
        object-position: center;
        object-fit: cover;
        border: 1px solid white;
        background-color: #c7e0f0;
      }

      .no-profile-img-label {
        color: $red;
        margin-top: -7rem;
        font-weight: bolder;
      }
    }
  }
}
